<template>
    <v-expansion-panels class="mb-6">
        <v-expansion-panel>
            <v-expansion-panel-header v-slot="{ open }">
                <v-row no-gutters :class="open ? 'px-4' : 'px-2'">
                    <v-col cols="12" :class="open ? 'text-h6 font-weight-bold' : 'd-sm-flex d-lg-flex d-xl-flex font-weight-bold col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12'">
                        {{ open ? 'Advance Filter' : 'Expand to Filter' }}
                    </v-col>
                    <v-col cols="8" class="d-none d-md-flex d-lg-flex d-xl-flex text--secondary">
                        <v-fade-transition leave-absolute>
                            <v-row v-if="!open" class="w-100">
                                <v-col class="text-start col-lg-4 col-xl-4 col-md-4 col-sm-4 col-xs-3">
                                    Date/Time: {{ dateTime || 'Not set' }}
                                </v-col>
                                <v-col class="text-start col-lg-4 col-xl-4 col-md-4 col-sm-4 col-xs-3">
                                    Type: {{ model.type ? typeList.find(item => item.value == model.type).text : 'Not set' }}
                                </v-col>
                                <v-col class="text-start col-lg-4 col-xl-4 col-md-4 col-sm-4 col-xs-3">
                                    Status: {{ model.status ? statusList.find(item => item.value == model.status).text : 'Not set' }}
                                </v-col>
                                <v-col class="text-start col-lg-4 col-xl-4 col-md-4 col-sm-4 col-xs-3">
                                    Agent: {{ agentName || 'Not set' }}
                                </v-col>
                                <v-col class="text-start col-lg-4 col-xl-4 col-md-4 col-sm-4 col-xs-3">
                                    Client: {{ clientName || 'Not set' }}
                                </v-col>
                                <v-col class="text-start col-lg-4 col-xl-4 col-md-4 col-sm-4 col-xs-3">
                                    Location: {{ locationName || 'Not set' }}
                                </v-col>
                            </v-row>
                        </v-fade-transition>
                    </v-col>
                </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-form lazy-validation @submit.prevent="$emit('search', model)">
                <v-row class="px-5 pb-0">
                    <v-col cols="12" class="col-md-6 col-sm-6 col-lg-4">
                        <v-menu v-model="menu" transition="scale-transition" :close-on-content-click="false" offset-y >
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                    <v-tooltip top :disabled="!model.dateTime">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field dense v-model="dateTime" outlined placeholder="Date / Time" prepend-inner-icon="mdi-calendar"
                                                readonly hide-details="auto" clearable v-bind="attrs" v-on="on" @click:clear="clearDate">
                                            </v-text-field>
                                        </template>
                                        <span>{{model.dateTime}}</span>
                                    </v-tooltip>
                                </div>
                            </template>
                            <div class="range-calendar">
                                <vc-date-picker mode="dateTime" class="date-picker-range" v-model="date" is-range :masks="{ L: 'YYYY-MM-DD HH:mm:ss' }"
                                    :model-config="{type: 'string', mask: 'YYYY-MM-DD HH:mm:ss' }" @input="!reseted && dateChange($event)" timezone="Australia/Melbourne"/>                           
                                <v-btn class="ma-2"  dark color="primary" @click="menu=false;">
                                    Ok
                                </v-btn>
                            </div>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" class="col-md-6 col-sm-6 col-lg-2">
                        <v-select dense v-model="model.type" :items="typeList" outlined placeholder="Select Type"
                            :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true}"
                            hide-details="auto" clearable @click:clear="$emit('remove', 'type')">
                        </v-select>
                    </v-col>
                    <v-col cols="12" class="col-md-6 col-sm-6 col-lg-3">
                        <v-select dense v-model="model.status" :items="statusList" outlined placeholder="Select Status"
                            :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true}"
                            hide-details="auto" clearable @click:clear="$emit('remove', 'status')">
                        </v-select>
                    </v-col>
                    <v-col cols="12" class="col-md-6 col-sm-6 col-lg-3">
                        <v-select dense v-model="model.agent_id" :items="agentsList" outlined placeholder="---Select Agent---"
                            :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true}"
                            hide-details="auto" clearable @click:clear="$emit('remove', 'agent_id')"
                            @change="model.agent_name = agentName">
                        </v-select>
                    </v-col>
                    <v-col cols="12" class="col-md-6 col-sm-6 col-lg-3">
                        <v-select dense v-model="model.client_id" :items="clientsList" outlined placeholder="---Select Client---"
                            :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true}"
                            hide-details="auto" clearable @click:clear="$emit('remove', 'client_id')">
                        </v-select>
                    </v-col>
                    <v-col cols="12" class="col-md-6 col-sm-6 col-lg-3">
                        <v-select dense v-model="model.location_id" :items="locationsList" outlined placeholder="---Select Location---"
                            :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true}"
                            hide-details="auto" clearable @click:clear="$emit('remove', 'location_id')"
                            @change="model.location_name = locationName">
                        </v-select>
                    </v-col>
                    <v-col cols="12" class="col-md-6">
                        <v-btn type="submit" color="primary"  class="mr-4 mt-2" :loading="loading"
                            :disabled="loading || !Object.keys(model).some(key => model[key])">
                            Search
                        </v-btn>
                        <v-btn type="button" outlined color="primary" class="mr-4 mt-2" @click="resetForm"
                            :disabled="loading">
                            Reset
                        </v-btn>
                    </v-col>
                </v-row>
                </v-form>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';


export default {
    props: ['loading'],
    emits: [ 'search', 'reset', 'remove'],
    data: (vm) => ({
        statusList: [{text: 'Meta Data', value: 'metadata'}, {text: 'Ready', value: 'ready'},
            {text: 'Uploaded', value: 'uploaded'}],
        typeList: [{ text: 'Arch', value: 'arch'}, {text: 'Chasis', value: 'chasis'}, {text: 'Tire', value: 'tire'}],
        model: {
            ts: null,
            te: null,
            status: null,
            type: null,
            agent_id: null,
            client_id: null,
            location_id: null
        },
        menu: false,
        date: {
            start: vm.$helpers.getJsDateBeginEnd('start'),
            end: vm.$helpers.getJsDateBeginEnd(),
        },
        reseted: false,
    }),
    computed: {
        ...mapGetters(['locationsList', 'agentsList', 'clientsList']),

        dateTime: {
            get() {
                if (!this.model.ts && !this.model.te) return null;
                return (`${this.model.ts ? this.$helpers.getMomentDatas('DD-MM-YYYY hh:mm A', this.model.ts) + ' - ': ''}${this.$helpers.getMomentDatas('DD-MM-YYYY hh:mm A', this.model.te)}`).trim();
            },
            set(value) {
                if(!value) this.model.ts = this.model.te = null;
            }
        },
        agentName() {
            const agent = _.find(this.agentsList, item => item.value === this.model.agent_id);
            return this.model.agent_id && agent ? agent.text : null;
        },
        clientName() {
            const client = _.find(this.clientsList, item => item.value === this.model.client_id);
            return this.model.client_id && client ? client.text : null;
        },
        locationName() {
            const location = _.find(this.locationsList, item => item.value === this.model.location_id);
            return this.model.location_id && location ? location.text : null;
        },
    },
    created() {
        this.getLocationLists();
        this.getAgentLists();
        this.getClientLists();
    },
    methods: {
        ...mapActions(['getLocationLists', 'getAgentLists', 'getClientLists']),

        dateChange(ev) {
            if (ev.start) this.model.ts = ev.start;
            if (ev.end) this.model.te = ev.end;
        },
        clearDate(){
            this.$emit('remove', 'ts');
            this.$emit('remove', 'te');
            this.model.ts = this.model.te = null;
            this.date = {
                start:  this.$helpers.getJsDateBeginEnd('start'),
                end: this.$helpers.getJsDateBeginEnd(),
            }
        },
        resetForm() {
            this.reseted = true;
            this.$emit('reset');
            this.model = {
                ts: null,
                te: null,
                status: null,
                type: null,
                agent_id: null,
                client_id: null,
                location_id: null
            };
            this.date = {
                start:  this.$helpers.getJsDateBeginEnd('start'),
                end: this.$helpers.getJsDateBeginEnd(),
            }
            setTimeout(() => {
                this.reseted = false;
            });
        }
    }
}
</script>
